<template>
    <div class="card mb-0 border">
        <div class="card-header">
            <h3 class="card-title text-dark">Edit Profile</h3>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="form-label">First Name</label>
                        <input v-model="user_.first_name" :class="[v$.first_name.$error ? 'border-danger': '']" type="text" class="form-control" name="first_name" placeholder="John">
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="form-label">Last Name</label>
                        <input v-model="user_.last_name" :class="[v$.last_name.$error ? 'border-danger': '']" type="text" class="form-control" name="last_name" placeholder="Doe">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="form-label">Mobile Number</label>
                        <PhoneInput v-if="user_.dialCode" :dialCode="parseInt(user_.dialCode)" v-model="user_.phone" @country-changed="updateCountryCode" :class='[v$.phone.$error && "border-danger"]' />
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="form-label">Birthday {{ v$.birth_date.$error }}</label>
                        <Datepicker v-model="user_.birth_date" :enableTimePicker="false" :input-class-name="v$.birth_date.$error?'border-danger':''" :autoApply="true" placeholder="Select Birth Date"></Datepicker>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="form-label">Gender</label>
                        <select v-model="user_.gender" :class="[v$.gender.$error ? 'border-danger': '']" name="gender" class="form-control">
                            <option value="">Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="form-label">Address</label>
                        <input v-model="user_.address.complete_address" :class="[v$.address.complete_address.$error ? 'border-danger': '']" type="text" class="form-control" name="address" placeholder="Search Address" ref="origin">
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label class="form-label">Apt / Suite / Unit</label>
                        <input v-model="user_.apt_suite" type="text" class="form-control" placeholder="eg. 200" />
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12 mt-4">
                    <button type="button" class="btn default-button" v-on:click.prevent="updateProfile">Update</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
    import { onMounted, reactive, ref } from 'vue';
    import axios from 'axios'
    import Datepicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css'
    import useVuelidate from '@vuelidate/core'
    import { required } from '@vuelidate/validators'
    import PhoneInput from '@/components/globals/PhoneInput'
    import { useToast } from "vue-toastification"
    import { useStorage } from "vue3-storage";
    import { useRouter } from 'vue-router'

    const toast = useToast();
    const storage = useStorage()
    const router = useRouter()
    const origin = ref(null);
    const autocomplete = ref(null);

    // Add form validation
    const user_ = reactive({
        first_name:'',
        last_name:'',
        phone:'',
        dialCode: null,
        gender:'',
        birth_date:null,
        address:{
            complete_address:'',
            lat:'',
            lng:'',
            country:'',
            state:'',
            city:'',
            street:'',
            zip:''
        },
        apt_suite: '',
        // bio: ''
    })

    const rules = {
        first_name:{ required },
        last_name:{ required },
        phone:{ required },
        gender:{ required },
        birth_date:{ required },
        address:{
            complete_address:{ required }
        }
    }
    const v$ = useVuelidate(rules, user_)

    function updateCountryCode(data){
        if (data.dialCode) {
            user_.dialCode = data.dialCode
        }
    }
    function getUserDetail(){
        axios.get('user_detail').then(function (response) {
            if (response.data) {
                let user_detail = response.data
                user_.first_name    = user_detail.first_name
                user_.last_name     = user_detail.last_name
                user_.gender        = user_detail.gender
                user_.dialCode      = parseInt(user_detail?.phone.slice(0, -10))
                user_.phone         = user_detail?.phone.slice(-10)
                user_.birth_date    = user_detail.birth_date
                user_.apt_suite     = user_detail.apt_suite
                // Address
                user_.address.complete_address  = user_detail.address
                user_.address.country           = user_detail.country
                user_.address.state             = user_detail.state
                user_.address.city              = user_detail.city
                user_.address.street            = user_detail.street
                user_.address.zip               = user_detail.zip
                user_.address.lat               = user_detail.lat
                user_.address.lng               = user_detail.lng
            }
        })
        .catch(error => {
            if (error.response) {
                console.log(error.response.data.message)
            }
        });
    }
    function updateProfile(){
        v$.value.$touch()
        if (!v$.value.$error) {
            console.log(user_)
            axios.post('update_user_profile',user_).then(function (response) {
                if (response.data.status == 'success') {
                    toast.success(response.data.message);
                }else if (response.data.status == 'login') {
                    storage.removeStorageSync("user")
                    storage.removeStorageSync("token")
                    storage.removeStorageSync("business")
                    router.push({ name: 'Home'})
                }
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status == 422) {
                        toast.error(error.response.data.message);
                    }else if (error.response.status == 401) {
                        toast.error(error.response.data.message,{position: "top-right"});
                    }
                }
            });
        }
    }

    function getAddressArray(place){
        var comp_address = {complete_address:'',lat:'',lng:'',street:'',city:'',state:'',country:'',zip:''}
        comp_address.complete_address = place.formatted_address
        comp_address.lat = place.geometry.location.lat()
        comp_address.lng = place.geometry.location.lng()
        for (var i = 0; i < place.address_components.length; i++) {
            var addressType = place.address_components[i].types[0];
            if (addressType == "street_number") {
                comp_address.street += place.address_components[i]['short_name']
            }else if (addressType == "route") {
                comp_address.street += ' '+place.address_components[i]['long_name']+' '
            }else if (addressType == "locality") {
                comp_address.city = place.address_components[i]['long_name']
            }else if (addressType == "administrative_area_level_1") {
                comp_address.state = place.address_components[i]['short_name']
            }else if (addressType == "country") {
                comp_address.country = place.address_components[i]['long_name']
            }else if (addressType == "postal_code") {
                comp_address.zip = place.address_components[i]['short_name']
            }
        }
        return comp_address
    }

    onMounted(() => {
        const input = origin.value;
        autocomplete.value = new window.google.maps.places.Autocomplete(input, { componentRestrictions: { country: ['US', 'CA','PK'] } });
        autocomplete.value.addListener('place_changed', function(){
            const place = autocomplete.value.getPlace();
            user_.address = getAddressArray(place)
        });

        getUserDetail()
    });
</script>