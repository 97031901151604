<template>
    <AppContainer>
        <ProfileEditForm/>
    </AppContainer>
</template>

<script>
import AppContainer from '@/components/containers/UserDashboardContainer.vue'
import ProfileEditForm from '@/components/userDashboard/profile/EditProfile.vue'
export default {
  name: 'EditPro',
  components: {
    AppContainer,
    ProfileEditForm
  }
}
</script>